import { DiagonalArrowRightUpOutline } from '@easy-eva-icons/react'
import { Table, useModalContext } from 'src/design-system'
import * as React from 'react'
import { openModal as openRailsModal } from '../../utils/open-modal'
import { OpenRolesOverflow, OpenRolesResource } from './open-roles-overflow'
import {
  PositionModalProps,
  POSITION_MODAL_ID,
} from 'components/position-modal/utils'

export type OpenRolesTableRow = {
  hiringLink: string
  id: number
  positionName: string
  teamName: string
}

type OpenRolesTableProps = {
  data: OpenRolesTableRow[]
  resource: OpenRolesResource
  publicPage?: boolean
  allowEdit?: boolean
}

export const OpenRolesTable: React.VFC<OpenRolesTableProps> = (props) => {
  const { data, resource, publicPage = true, allowEdit = false } = props

  const { openModal } = useModalContext()

  const positionClickHandler = (role: OpenRolesTableRow) => {
    if (publicPage) {
      openRailsModal(
        `/view_position_modal?position_id=${role.id}&public=${publicPage}`
      )
    } else {
      openModal<PositionModalProps>(POSITION_MODAL_ID, {
        positionId: role.id.toString(),
        source: 'open_roles_table',
      })
    }
  }

  return (
    <Table.Root>
      <Table.Head>
        <Table.Row>
          <Table.Header scope="col">Position</Table.Header>
          <Table.Header scope="col">Team</Table.Header>
          <Table.Header scope="col" srOnly>
            Actions
          </Table.Header>
        </Table.Row>
      </Table.Head>
      <Table.Body className="border-collapse bordered">
        {data.length === 0 && (
          <Table.Row>
            <Table.Cell colSpan={3}>
              <div className="w-full flex justify-center">No open roles</div>
            </Table.Cell>
          </Table.Row>
        )}
        {data.map((role, i) => (
          <Table.Row key={`role-row-${i}`}>
            <Table.Cell>
              <button
                onClick={() => positionClickHandler(role)}
                className="text-link"
              >
                {role.positionName}
              </button>
            </Table.Cell>
            <Table.Cell>{role.teamName}</Table.Cell>
            <Table.Cell>
              <a
                href={role.hiringLink}
                target="_blank"
                rel="noreferrer"
                className="w-full text-right"
              >
                View details
                <DiagonalArrowRightUpOutline className="-mt-2" />
              </a>
              {allowEdit && (
                <OpenRolesOverflow
                  resource={resource}
                  positionId={role.id}
                  positionName={role.positionName}
                />
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  )
}
