import { AvatarButton, TUser } from 'src/design-system'
import { Table, Tooltip } from 'src/design-system'
import cn from 'classnames'
import { useRailsContext } from 'components/rails-context'
import * as React from 'react'
import { useStore } from 'store/context'

export type TUserRow = TUser & { teamName: string; fullName: string }

const TruncatedName = ({ name }: { name: string }) => {
  if (name.length < 20) return <>{name}</>
  return (
    <Tooltip content={name} side="top">
      <span>{`${name.substring(0, 20)}...`}</span>
    </Tooltip>
  )
}

export const OrgUsersTable: React.VFC = () => {
  const { org } = useRailsContext()
  const { users } = useStore()

  return (
    <Table.Root>
      <Table.Head>
        <Table.Row>
          <Table.Header scope="col">Name</Table.Header>
          <Table.Header scope="col">Team</Table.Header>
          {org?.membershipsVisible && (
            <>
              <Table.Header scope="col">Role</Table.Header>
            </>
          )}
          <Table.Header scope="col" srOnly>
            Actions
          </Table.Header>
        </Table.Row>
      </Table.Head>
      <Table.Body className="border-collapse bordered">
        {users.active.map((user, i) => (
          <Table.Row
            key={`user-row-${i}`}
            className={cn(user.state !== 'active' && 'opacity-75')}
          >
            <Table.Cell>
              <AvatarButton
                fallbackText={user.fname[0] + (user.lname ? user.lname[0] : '')}
                alt={user.fullName}
                src={user.avatarUrl}
                href={`/users/${user.slug}`}
                size="sm"
                isInactive={user.state !== 'active'}
              />
              <TruncatedName name={user.fullName} />
              {user.state === 'archived' && ' (archived)'}
              {user.state === 'not_invited' && ' (not yet invited)'}
            </Table.Cell>
            <Table.Cell>{user.team?.name}</Table.Cell>
            {org?.membershipsVisible && (
              <Table.Cell>{user.position?.name}</Table.Cell>
            )}
            <Table.Cell right>
              <a
                href={`/users/${user?.slug}`}
                data-turbo-frame="content"
                data-turbo-action="advance"
              >
                View profile
              </a>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  )
}
