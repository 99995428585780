import { Count } from 'src/design-system'
import * as React from 'react'
import cn from 'classnames'
import { Org } from '../../types/entities'
import { trackOrgDetailNavClick } from '../org-detail-nav/tracker'
import { useIsActive } from '../../utils/use-is-active'
import * as SecondaryNav from 'components/secondary-nav'
import { PackageProtect } from 'components/package-protect'

type OrgSecondaryNavProps = {
  org: Org
  activePath?: string
  teamsCount: number
  peopleCount: number
  showReadme: boolean
  showPeople: boolean
  showOpenRoles: boolean
  readmeTitle: boolean
  openRolesCount: number
  routes: {
    teams: string
    readme: string
    editReadme?: string
    openRoles: string
    people?: string
    wins: string
  }
}

export const OrgSecondaryNav: React.VFC<OrgSecondaryNavProps> = (props) => {
  const {
    org,
    activePath,
    teamsCount,
    peopleCount,
    showReadme,
    showPeople,
    showOpenRoles,
    readmeTitle,
    openRolesCount,
    routes,
  } = props

  const teamsPathActive = useIsActive(routes.teams, activePath)

  const orgUsersPathActive = useIsActive(routes.people, activePath)
  const openRolesPathActive = useIsActive(routes.openRoles, activePath)

  const trackingEvents = {
    teams: () =>
      trackOrgDetailNavClick({
        title: 'Clicking teams',
        href: routes.teams,
      }),
    readme: () =>
      trackOrgDetailNavClick({
        title: 'Clicking readme',
        href: routes.readme,
      }),
    orgUsers: () =>
      trackOrgDetailNavClick({
        title: 'Clicking people',
        href: routes.people,
      }),
    openRoles: () =>
      trackOrgDetailNavClick({
        title: 'Clicking open roles',
        href: routes.openRoles,
      }),
    wins: () =>
      trackOrgDetailNavClick({
        title: 'Clicking wins',
        href: routes.wins,
      }),
  }

  const countClassName = (pathIsActive: boolean) => {
    return cn(
      pathIsActive && 'text-theme-30 bg-white',
      'group-hover:bg-white transition-colors'
    )
  }

  return (
    <SecondaryNav.Root>
      <SecondaryNav.Header>{org.name}</SecondaryNav.Header>
      <SecondaryNav.Body>
        <SecondaryNav.Tabs>
          <SecondaryNav.Tab
            exact
            href={routes.teams}
            onClick={trackingEvents.teams}
            className="group"
          >
            Teams{' '}
            <Count className={countClassName(teamsPathActive)}>
              {teamsCount}
            </Count>
          </SecondaryNav.Tab>
          {showReadme && (
            <SecondaryNav.Tab
              href={routes.readme}
              onClick={trackingEvents.readme}
            >
              {readmeTitle}
            </SecondaryNav.Tab>
          )}
          {showPeople && routes.people && (
            <SecondaryNav.Tab
              href={routes.people}
              onClick={trackingEvents.orgUsers}
              className="group"
            >
              People{' '}
              <Count className={countClassName(orgUsersPathActive)}>
                {peopleCount}
              </Count>
            </SecondaryNav.Tab>
          )}
          {showOpenRoles && (
            <SecondaryNav.Tab
              href={routes.openRoles}
              onClick={trackingEvents.openRoles}
            >
              Open positions{' '}
              <Count className={countClassName(openRolesPathActive)}>
                {openRolesCount}
              </Count>
            </SecondaryNav.Tab>
          )}
          <PackageProtect>
            <SecondaryNav.Tab href={routes.wins} onClick={trackingEvents.wins}>
              Wins
            </SecondaryNav.Tab>
          </PackageProtect>
        </SecondaryNav.Tabs>
      </SecondaryNav.Body>
    </SecondaryNav.Root>
  )
}
