import * as React from 'react'
import { Tabs, Tab, Count } from 'src/design-system'
import { Org } from '../../types/entities'
import { useIsActive } from '../../utils/use-is-active'
import { trackOrgDetailNavClick } from './tracker'

type OrgDetailNavProps = {
  org: Org
  activePath?: string
  teamsCount: number
  peopleCount: number
  showReadme: boolean
  showPeople: boolean
  showOpenRoles: boolean
  readmeTitle: boolean
  openRolesCount: number
  routes: {
    teams: string
    readme: string
    editReadme?: string
    openRoles: string
    people?: string
    wins: string
  }
}

export const OrgDetailNav: React.VFC<OrgDetailNavProps> = (props) => {
  const {
    activePath,
    teamsCount,
    peopleCount,
    showReadme,
    showPeople,
    showOpenRoles,
    readmeTitle,
    openRolesCount,
    routes,
  } = props

  const teamsPathActive = useIsActive(routes.teams, activePath)

  const readmePathActive = useIsActive(
    [routes.readme, routes.editReadme],
    activePath
  )
  const orgUsersPathActive = useIsActive(routes.people, activePath)
  const openRolesPathActive = useIsActive(routes.openRoles, activePath)
  const winsActivePath = useIsActive(routes.wins, activePath)

  const trackingEvents = {
    teams: () =>
      trackOrgDetailNavClick({
        title: 'Clicking teams',
        href: routes.teams,
      }),
    readme: () =>
      trackOrgDetailNavClick({
        title: 'Clicking readme',
        href: routes.readme,
      }),
    orgUsers: () =>
      trackOrgDetailNavClick({
        title: 'Clicking people',
        href: routes.people,
      }),
    openRoles: () =>
      trackOrgDetailNavClick({
        title: 'Clicking open roles',
        href: routes.openRoles,
      }),
    wins: () =>
      trackOrgDetailNavClick({
        title: 'Clicking wins',
        href: routes.wins,
      }),
  }

  const textColour = (pathIsActive: boolean) => {
    return pathIsActive ? 'text-theme-30' : ''
  }

  return (
    <div className="org-detail-nav">
      <Tabs>
        <Tab
          as="a"
          data-turbo-action="advance"
          href={routes.teams}
          active={teamsPathActive}
          onClick={trackingEvents.teams}
        >
          Teams{' '}
          <Count className={textColour(teamsPathActive)}>{teamsCount}</Count>
        </Tab>
        {showReadme && (
          <Tab
            as="a"
            data-turbo-action="advance"
            href={routes.readme}
            active={readmePathActive}
            onClick={trackingEvents.readme}
          >
            {readmeTitle}
          </Tab>
        )}
        {showPeople && (
          <Tab
            as="a"
            data-turbo-action="advance"
            href={routes.people}
            active={orgUsersPathActive}
            onClick={trackingEvents.orgUsers}
          >
            People{' '}
            <Count className={textColour(orgUsersPathActive)}>
              {peopleCount}
            </Count>
          </Tab>
        )}
        {showOpenRoles && (
          <Tab
            as="a"
            data-turbo-action="advance"
            href={routes.openRoles}
            active={openRolesPathActive}
            onClick={trackingEvents.openRoles}
          >
            Open positions{' '}
            <Count className={textColour(openRolesPathActive)}>
              {openRolesCount}
            </Count>
          </Tab>
        )}
        <Tab
          as="a"
          data-turbo-action="advance"
          href={routes.wins}
          active={winsActivePath}
          onClick={trackingEvents.wins}
        >
          Wins
        </Tab>
      </Tabs>
    </div>
  )
}
