import { trackEvent } from '../../services/event-tracker'

type NavLink = {
  title: string
  href?: string
}

export const trackOrgDetailNavClick = (link: NavLink) => {
  trackEvent('$track_org_detail_nav_click', { link })
}
