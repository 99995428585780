import { forRails } from 'components/for-rails'
import { VFC } from 'react'
import { OrgUsersTable } from '../src/components/org-users-table'
import { OpenRolesTable } from '../src/components/open-roles-table'
import { registerComponents } from '../src/utils/react/registry'
import { OrgDetailNav } from 'components/org-detail-nav'
import { OrgSecondaryNav } from 'components/org-secondary-nav'

registerComponents({
  OrgDetailNav: forRails(OrgDetailNav),
  OrgUsersTable: forRails(OrgUsersTable),
  OpenRolesTable: forRails(OpenRolesTable),
  OrgSecondaryNav: forRails(OrgSecondaryNav),
})
